import { Trans } from "react-i18next";
import { useSetRecoilState } from "recoil";

import {
  useAppMutation,
  useAppQueryWithQueryKeyFactory,
} from "@sellernote/_shared/src/services/query";
import { MINUTE_AS_MILLISECONDS } from "@sellernote/_shared/src/utils/common/date";

import {
  QueryResponseHandlerFailureModalInfo,
  QueryResponseHandlerSuccessModalInfo,
} from "../../components/QueryResponseHandler";

import {
  CHANGE_USER_EMAIL_REQ,
  CHANGE_USER_EMAIL_RES,
  CHANGE_USER_INFO_REQ,
  CHANGE_USER_NOTIFICATION_SUBSCRIBE_OPTION_REQ,
  CHANGE_USER_NOTIFICATION_SUBSCRIBE_OPTION_RES,
  CHANGE_USER_PASSWORD_REQ,
  CHANGE_USER_PASSWORD_RES,
  DELETE_USER_DASHBOARD_ISSUE_BID_REQ,
  GET_USER_DASHBOARD_LEGACY_RES,
  GET_USER_DASHBOARD_RES,
  GET_USER_EXPORT_DASHBOARD_RES,
  GET_USER_INFO_RES,
  WITHDRAW_USER_ACCOUNT_REQ,
  WITHDRAW_USER_ACCOUNT_RES,
} from "../../api-interfaces/shipda-api/user";
import { USER_ATOMS } from "../../states/common/user";

export const USER_QUERY_KEY_GEN = {
  all: () => [{ scope: "forwarding/USER_QUERY" }] as const,

  userInfo: () =>
    [{ ...USER_QUERY_KEY_GEN.all()[0], subScope: "userInfo" }] as const,
  getUserInfo: () =>
    [
      {
        ...USER_QUERY_KEY_GEN.userInfo()[0],
      },
    ] as const,

  userDashboard: () =>
    [{ ...USER_QUERY_KEY_GEN.all()[0], subScope: "userDashboard" }] as const,
  getUserDashboard: () =>
    [
      {
        ...USER_QUERY_KEY_GEN.userDashboard()[0],
      },
    ] as const,

  userDashboardLegacy: () =>
    [
      { ...USER_QUERY_KEY_GEN.all()[0], subScope: "userDashboardLegacy" },
    ] as const,
  getUserDashboardLegacy: () =>
    [
      {
        ...USER_QUERY_KEY_GEN.userDashboardLegacy()[0],
      },
    ] as const,

  userExportDashboard: () =>
    [
      { ...USER_QUERY_KEY_GEN.all()[0], subScope: "userExportDashboard" },
    ] as const,

  getUserExportDashboard: () =>
    [
      {
        ...USER_QUERY_KEY_GEN.userExportDashboard()[0],
      },
    ] as const,
};

function useGetUserInfo({
  enabled,
  retry = 3,
  onSuccess,
  hidesLoading,
}: {
  enabled?: boolean;
  retry?: boolean | number;
  onSuccess?: (data: GET_USER_INFO_RES) => void;
  hidesLoading?: boolean;
}) {
  const setUserTeamAuthorityInfo = useSetRecoilState(
    USER_ATOMS.USER_TEAM_AUTHORITY_INFO
  );

  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<typeof USER_QUERY_KEY_GEN.getUserInfo>,
    GET_USER_INFO_RES
  >({
    queryKey: USER_QUERY_KEY_GEN.getUserInfo(),
    requestOptions: {
      method: "get",
      path: "/users/detail",
      apiType: "ShipdaDefaultNew",
    },

    retry,

    staleTime: 10 * MINUTE_AS_MILLISECONDS,
    cacheTime: 11 * MINUTE_AS_MILLISECONDS,

    ...(typeof enabled === "boolean" ? { enabled } : {}),

    hidesLoading,

    // 사이트 전역에서 유저가 접속한 팀에서의 포지션과 권한 정보가 필요하므로 이를 전역 상태로 저장한다. (개인/팀 계정 여부)
    onSuccess: (data) => {
      setUserTeamAuthorityInfo({ ...data.access, isPrivate: data.isPrivate });

      if (onSuccess) onSuccess(data);
    },
  });

  return { ...queryResult };
}

function useGetUserDashboard({
  enabled,
  loadingLabel,
  hidesLoading,
}: {
  enabled?: boolean;
  loadingLabel?: React.ReactNode;
  hidesLoading?: boolean;
}) {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<typeof USER_QUERY_KEY_GEN.getUserDashboard>,
    GET_USER_DASHBOARD_RES
  >({
    queryKey: USER_QUERY_KEY_GEN.getUserDashboard(),
    requestOptions: {
      method: "get",
      path: "/statistics/v3/dash",
      apiType: "ShipdaDefaultNew",
    },
    loadingLabel,
    hidesLoading,

    ...(typeof enabled === "boolean" ? { enabled } : {}),
  });

  return { ...queryResult };
}

function useDeleteUserDashboardIssueTransit() {
  const mutation = useAppMutation<DELETE_USER_DASHBOARD_ISSUE_BID_REQ, unknown>(
    {
      requestOptions: {
        path: "/bid/v2/check",
        method: "patch",
        apiType: "ShipdaDefaultNew",
      },
    }
  );

  return { ...mutation };
}

function useGetUserDashboardLegacy({
  enabled,
  loadingLabel,
}: {
  enabled?: boolean;
  loadingLabel?: React.ReactNode;
}) {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<typeof USER_QUERY_KEY_GEN.getUserDashboardLegacy>,
    GET_USER_DASHBOARD_LEGACY_RES
  >({
    queryKey: USER_QUERY_KEY_GEN.getUserDashboardLegacy(),
    requestOptions: {
      method: "get",
      path: "/statistics/web/dash/user",
      apiType: "ShipdaDefaultNew",
    },
    loadingLabel,

    ...(typeof enabled === "boolean" ? { enabled } : {}),
  });

  return { ...queryResult };
}

function useChangeUserPassword(props?: {
  successModalInfo?: QueryResponseHandlerSuccessModalInfo;
  failureModalInfo?: QueryResponseHandlerFailureModalInfo;
  onSuccess?: () => void;
  onError?: () => void;
}) {
  const { successModalInfo, failureModalInfo, onSuccess, onError } =
    props || {};

  const mutation = useAppMutation<
    CHANGE_USER_PASSWORD_REQ,
    CHANGE_USER_PASSWORD_RES
  >({
    requestOptions: {
      path: "/users",
      method: "put",
      apiType: "ShipdaDefaultNew",
    },

    onSuccess: (data) => {
      const { refreshToken } = data;

      const isAutoLogin = window.localStorage.getItem("accessToken");

      if (isAutoLogin) {
        window.localStorage.setItem("refreshToken", refreshToken);
      } else {
        window.sessionStorage.setItem("refreshToken", refreshToken);
      }

      if (onSuccess) onSuccess();
    },

    onError,

    successModalInfo,
    failureModalInfo,
  });

  return { ...mutation };
}

function useChangeUserEmail(props?: {
  successModalInfo?: QueryResponseHandlerSuccessModalInfo;
  failureModalInfo?: QueryResponseHandlerFailureModalInfo;
  onSuccess?: () => void;
}) {
  const { successModalInfo, failureModalInfo, onSuccess } = props || {};

  const mutation = useAppMutation<CHANGE_USER_EMAIL_REQ, CHANGE_USER_EMAIL_RES>(
    {
      requestOptions: {
        path: "/users",
        method: "put",
        apiType: "ShipdaDefaultNew",
      },
      successModalInfo,
      failureModalInfo,

      onSuccess,
    }
  );

  return { ...mutation };
}

function useWithdrawUserAccount(props?: {
  successModalInfo?: QueryResponseHandlerSuccessModalInfo;
  failureModalInfo?: QueryResponseHandlerFailureModalInfo;
  onSuccess?: () => void;
}) {
  const { successModalInfo, failureModalInfo, onSuccess } = props || {};

  const mutation = useAppMutation<
    WITHDRAW_USER_ACCOUNT_REQ,
    WITHDRAW_USER_ACCOUNT_RES
  >({
    requestOptions: {
      path: `/users`,
      method: "delete",
      apiType: "ShipdaDefaultNew",
    },

    successModalInfo,
    failureModalInfo,

    onSuccess,
  });

  return { ...mutation };
}

function useChangeUserNotificationSubscribeOption(props?: {
  successModalInfo?: QueryResponseHandlerSuccessModalInfo;
  failureModalInfo?: QueryResponseHandlerFailureModalInfo;
  onSuccess?: () => void;
}) {
  const { successModalInfo, failureModalInfo, onSuccess } = props || {};

  const mutation = useAppMutation<
    CHANGE_USER_NOTIFICATION_SUBSCRIBE_OPTION_REQ,
    CHANGE_USER_NOTIFICATION_SUBSCRIBE_OPTION_RES
  >({
    requestOptions: {
      path: "/users/notiManagement",
      method: "put",
      apiType: "ShipdaDefaultNew",
    },
    successModalInfo,
    failureModalInfo,

    onSuccess,
  });

  return { ...mutation };
}

function useChangeUserInfo({
  onSuccess,
  onError,
}: {
  onSuccess?: () => void;
  onError?: () => void;
}) {
  const mutation = useAppMutation<CHANGE_USER_INFO_REQ, GET_USER_INFO_RES>({
    requestOptions: {
      path: "/users",
      method: "put",
      apiType: "ShipdaDefaultNew",
    },
    failureModalInfo: {
      customizeMessage: (failureInfo) => {
        if (failureInfo?.error === "duplicate data exists") {
          return {
            messageType: "titleOnly",
            title: <Trans i18nKey="user-info:API_정보변경_중복이메일" />,
          };
        }

        return {
          messageType: "titleOnly",
          title: <Trans i18nKey="user-info:API_정보변경_처리오류" />,
        };
      },
    },

    onError,
    onSuccess,
  });

  return { ...mutation };
}

function useGetUserExportDashboard({
  enabled,
  loadingLabel,
  hidesLoading,
}: {
  enabled?: boolean;
  loadingLabel?: React.ReactNode;
  hidesLoading?: boolean;
}) {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<typeof USER_QUERY_KEY_GEN.getUserExportDashboard>,
    GET_USER_EXPORT_DASHBOARD_RES
  >({
    queryKey: USER_QUERY_KEY_GEN.getUserExportDashboard(),
    requestOptions: {
      method: "get",
      path: "/statistics/v3/dash/export",
      apiType: "ShipdaDefaultNew",
    },
    loadingLabel,
    hidesLoading,

    ...(typeof enabled === "boolean" ? { enabled } : {}),
  });

  return { ...queryResult };
}
const USER_QUERY = {
  useGetUserInfo,
  useGetUserDashboard,
  useGetUserDashboardLegacy,
  useDeleteUserDashboardIssueTransit,
  useChangeUserPassword,
  useChangeUserEmail,
  useWithdrawUserAccount,
  useChangeUserNotificationSubscribeOption,
  useChangeUserInfo,
  useGetUserExportDashboard,
};

export default USER_QUERY;
