import React, { useMemo } from "react";
import { useState } from "react";

import Portal from "@sellernote/_shared/src/components/Portal";
import Button from "@sellernote/_shared/src/componentsToMoveToV1/button/Button";
import { useCookie } from "@sellernote/_shared/src/services/cookie";

import Styled from "./index.styles";

/**
 * Modal과 유사하지만, 팝업의 툭수기능(노출 기한 설정, 다시보지않기 등)을 갖춤
 */
export default function PopUp({
  popUpKey,
  activePeriod,
  content,
  onClose,
  className,
}: {
  /**
   * 팝업을 구분하는 unique한 key (쿠키 셋팅등에 사용)
   */
  popUpKey: string;
  /**
   * 팝업이 활성화 되는 기간으로, '다시보지 않기' 상태면 무시된다.
   * Date객체는 UTC string을 통해 생성한다
   * - UTC string 확인법:
   *    - new Date(원하는 시간(현지시간기준)).toISOString()
   *    - ex) new Date('2022-03-31T11:35:00').toISOString()
   * - UTC string을 통한 Date객체 생성: new Date('2022-03-31T02:27:56.638Z')
   */
  activePeriod: { start: Date; end: Date };
  /**
   * (푸터 액션을 제외한)팝업 내용은 자유롭게 만들 수 있도록 UI를 전달받아 사용
   */
  content: React.ReactNode;
  onClose?: () => void;
  className?: string;
}) {
  const [isVisible, setIsVisible] = useState(true);

  const { cookieValue, addCookie, removeCookie } = useCookie(
    `popUp-${popUpKey}`
  );

  const isActive = useMemo(() => {
    if (!isVisible) return false;

    if (cookieValue) return false;

    const now = new Date();

    const afterStart = now >= activePeriod.start;
    const beforeEnd = now < activePeriod.end;

    return afterStart && beforeEnd;
  }, [cookieValue, activePeriod.start, activePeriod.end, isVisible]);

  if (!isActive) return null;

  return (
    <Portal selector={`#app-portal`}>
      <div>
        <Styled.containerWrapper
          className={`${className ? className : ""} pop-up`}
        >
          <Styled.container>
            <Styled.content>{content}</Styled.content>

            <Styled.footer>
              <Button
                theme="tertiary"
                size="block"
                label="다시 보지 않기"
                handleClick={() => {
                  // 기본 1년으로 둔다
                  addCookie("disabled", 360);
                }}
              />
              <Button
                theme="primary"
                size="block"
                label="닫기"
                handleClick={() => {
                  setIsVisible(false);

                  onClose && onClose();
                }}
              />
            </Styled.footer>
          </Styled.container>

          <Styled.dimmedBackground />
        </Styled.containerWrapper>
      </div>
    </Portal>
  );
}
